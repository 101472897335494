import React, {useContext, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import {useAuthUser} from '../../utility/AppHooks';
import AppContext from '../../utility/AppContext';
import {Divider,makeStyles} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {orange} from '@material-ui/core/colors';
import {Fonts} from '../../../shared/constants/AppEnums';
import Hidden from '@material-ui/core/Hidden';
import ChangePasswordPopup from './ChangepasswordPopup';
// import confirmation dialog
import ConfirmationDialog from './confirmationDialog';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {storeOnlineOfflineStatus} from '../../../redux/actions';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// for style
const useStyles = makeStyles((theme) => {
  return {
    userRoot: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      position: 'relative',
    },
    avatar: {
      fontSize: 24,
      backgroundColor: orange[500],
    },
    autowidht: {
      width: 'auto',
      height: 'auto',
      marginLeft: 6,
    },
    userInfo: {
      width: 'calc(100% - 75px)',
    },
    pointer: {
      cursor: 'pointer',
    },
    userName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      fontSize: 15,
      fontWeight: Fonts.MEDIUM,
      color: (props) =>
        props.bgType === 'colored' ? 'white' : theme.palette.text.primary,
    },

    outerBox: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
    },
    
    onlineStatus: {
      position: 'absolute',
      zIndex: 99,

      color: '#21d221',
      width: 18,
      height: 18,
      top: '-7%',
      left: '2%',
    },
    offlineStatus: {
      position: 'absolute',
      zIndex: 99,

      color: '#ff0000',
      width: 18,
      height: 18,
      top: '-7%',
      left: '2%',
    },
  };
});

const HorUserInfo = ({bgType = 'colored'}) => {
  const [changePassword, setChangePasswordPopup] = useState(false);
  const [getStatus, setgetStatus] = React.useState('Online');
   const [statusChange, setStatusChange] = React.useState({
    onlineshow: {display: 'block'},
    onlinehide: {display: 'none'},
    offlineshow: {display: 'block'},
    offlinehide: {display: 'none'},
  });
  const {themeMode} = useContext(AppContext);
  const [openpopup, setopenpopup] = useState(false);
  const user = useAuthUser();
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // changepassword open popup event
  const openChangepasswordPopup = () => {
    setAnchorEl(null);
    setChangePasswordPopup(true);
  };

  // logout open popup event
  const logoutClick = () => {
    setAnchorEl(null);
    setopenpopup(true);
  };
  const onGoToMyAccount = () => {
    setAnchorEl(null);
    history.push('/my-profile');
  }; 
   // online/offline change event
   const statusChanges = (val) => {
    console.log(user, 'userdata');
    let pushdata = {
      userId:'',
      userCode:  user.credentialid,
      onlineStatus: val,
      loginType: 'client',
    };
    dispatch(storeOnlineOfflineStatus(pushdata));
    setgetStatus(val);
    setAnchorEl(null);
  };
  const classes = useStyles({themeMode, bgType});

  return (
    <Box py={2} pl={{xs: 2, sm: 3, md: 5}}>
      <Box className={classes.outerBox}>
        <Box className={classes.userRoot} display='flex' onClick={handleClick}>
          {/* {user.photoURL ? (
          <Avatar className={classes.avatar} src={user.photoURL} />
        ) : (
          <Avatar className={classes.avatar}>{getUserAvatar()}</Avatar>
        )} */}

          <Avatar
            src='/assets/images/profile-user.png'
            className={classes.autowidht}
          />
          {
            getStatus === 'Online' ? (
              <FiberManualRecordIcon className={classes.onlineStatus} />
            ) : (
              <FiberManualRecordIcon className={classes.offlineStatus} />
            )
          } 
          <Hidden mdDown>
            <Box ml={3} className={classes.userName}>
              {/* display name check */}
              {user.displayName ? user.displayName : user.email}
              {/* <Box fontSize={13} fontWeight={Fonts.LIGHT}>
                {user.role}
              </Box> */}
            </Box>
          </Hidden>
        </Box>
      </Box>
      <Box className={classes.userInfo}>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          <MenuItem
            onClick={() => statusChanges('Online')}
            style={
              getStatus === 'Online'
                ? statusChange.onlinehide
                : statusChange.onlineshow
            }>
            {' '}
            <FiberManualRecordIcon className={classes.onlineStatus} />
            Online
          </MenuItem>
          <MenuItem
            onClick={() => statusChanges('Offline')}
            style={
              getStatus === 'Offline'
                ? statusChange.offlinehide
                : statusChange.offlineshow
            }>
            {' '}
            <FiberManualRecordIcon className={classes.offlineStatus} />
            Offline
          </MenuItem>
          <Divider></Divider>

          <MenuItem
            onClose={handleClose}
            onClick={() => openChangepasswordPopup()}>
            Change Password
          </MenuItem>
          <MenuItem
            onClick={() => {
              logoutClick();
            }}>
            Logout
          </MenuItem>
        </Menu>
        <ChangePasswordPopup
          open={changePassword}
          onDeny={setChangePasswordPopup}
          title='Change Password'
          //  dialogTitle={<IntlMessages id='Change Password' />}
        />
        <ConfirmationDialog opendialog={openpopup} onDeny={setopenpopup} />
      </Box>
    </Box>
  );
};

export default HorUserInfo;
