/************
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Call api response using reducers
 ************/
import {
  CLIENT_DASHBOARD_DETAILS,
  GET_HC_DATA,
  SEARCH_PATIENT_LIST,
  SEARCH_PATIENT_LIST_COUNT,
  DASHBOARD_PENDING_DATA,
  DASHBOARD_PENDING_COUNT,
  PATIENT_SUCCESS_MSG,
  ERX_SUCCESS_MSG,
  DASHBOARD_INSERT_PRESCRIPTION,
  DASHBOARD_INPROGRESSS_DATA,
  DASHBOARD_INPROGRESSS_COUNT,
  DOCTOR_SUCCESS_MSG,
  REJECT_ERX_MSG,
  DOCTOR_UPDATE_MSG,
  MEDICINE_SUCCESS_MSG,
  DASHBOARD_ERX_DOCPROCEDURE,
  DASHBOARD_REJECT_DATA,
  DASHBOARD_REJECT_COUNT,
  REJECT_ERX_SUCCESS_MSG,
  DASHBOARD_COMPLETED_COUNT,
  DASHBOARD_COMPLETED_DATA,
  DELETE_MEDCINE_SUCCESS_MSG,
  DEPARTMENT_MASTER,
  CHANGE_MOBILENO_RES,
  SEARCH_REPORTS_DATA,
  PATIENT_LISTS,
  GET_VENDOR_LIST,
  CLIENT_EXCEL_LIST,
  VACC_EXCEL_LIST,
  AGE_VACCINATION_LIST,
  APPOINTMENT_REASON,
  SETTINGS,
  pulseList,
  weightList,
  durationList,
  REFERRALLIST,
  DOSAGELIST,
  bpList,
  CLIENT_BILLING_DETAILS,
  spo2List,
  tempList,
  hemoglobinList,
  serumList,
  bloodureaList,
  VENDOR_LIST,
  USER_PERMISSIONS,
  tlcount,
  MEDIA_lIST,
  CLIENT_DOWNLOAD_LIST,
  CLIENT_INSERT_MSG,
  CLIENT_PATIENT_VACCLIST,
  CLIENT_PATIENT_VACCTOTAL,
  CLIENT_DOWNLOAD_LIST_COUNT,
  CLIENT_ERX_LIST,
  CLIENT_ERX_LIST_COUNT,
  CLIENT_ERX_TOTAL_FEES,
  REJECT_REASONS,
  APPOINTMENT_PATIENTS,
  CLIENT_LIST,
  OPERATOR_LIST,
  GET_CAMERA_LIST,
  GET_CLIENT_MASTERS,
  CLIENT_DASHBOARD_TAT,
  DOCTOR_INSERT_MSG,
  INSERT_OWN_ERX_SUCCESS,
  CLIENT_DOCTOR_LIST,
  UPLOAD_WARNING,
  INSERT_ERROR_INDEX,
  BUCKET_CAPTCHA,
  SUGGEST_PAT_MESSAGE,
  SUGGEST_PAT,
  OPDDIGITIZATION_COUNT,
  OPDDIGITIZATION_MEDICINE,
  DIGITIZATION_DISEASE,
  INVESTIGATION_LAB,
  INVESTIGATION_RADIO,
  ONLINE_OFFLINE_STATUS,
  CLIENT_DASHBOARD_SETTINGS,
} from '../../shared/constants/ActionTypes';

const initialState = {
  clientDownloadList: [],
  clientInsertMsg: '',
  healthCare: null,
  dashboardPendingList: {},
  pendingCount: 0,
  clientDownloadListCount: 0,
  weightList: [],
  tempList: {},
  owneRx_succes: '',
  spo2List: {},
  bucketCaptcha: {},
  suggestpat: [],
  suggestionpatmessage: '',
  clientErxList: {},
  clientErxListCount: 0,
  tlcount: {},
  patientsuccessmsg: null,
  erxsuccessmsg: null,
  rejecterxsuccessmsg: null,
  erxmedicinesuccessmsg: null,
  dashboarderxDocProcedure: [],
  dosageList: [],
  referralList: [],
  doctorDetails: {},
  dashboardinsertprescriptions: {},
  inprogresCount: 0,
  dashboardInprogressList: {},
  dashboardRejectList: {},
  dashboardRejectcountList: null,
  doctorsuccessmsg: null,
  doctorupdatemsg: null,
  rejectsuccessmsg: null,
  dashboardCompletedList: {},
  dashboardCompletedcountList: null,
  bpList: {},
  deletemedicinesuccessmsg: null,
  qualifications: [],
  departmentMaster: [],
  clientExcelDetails: {},
  vaccExcelDetails: [],
  age_vaccine_list: [],
  search_patient_list: [],
  search_patient_list_count: 0,
  optNotes: '',
  doctorSearchDatas: [],
  searchableDoctor: {},
  changemobilenores: '',
  searchReportsData: [],
  clientBillingDetails: {},
  patientListdata: [],
  searchReportsData: [],
  serumList: {},
  pulseList: {},
  hemoglobinList: {},
  bloodureaList: {},
  clientBillingDetails: {},
  clientDashboardDetails: {},
  clientTotalErxFees: 0,
  cameraDash: [],
  getClientlist: [],
  clientTAT: null,
  doctorInsertMsg: '',
  doctorList: [],
  uploadWarning: '',
  inserErrorIndex: '',
  opddigitization_report_count: {},
  opddigitization_report_medicine: [],
  digitization_report_diseases: [],
  inves_labtype: [],
  inves_radiotype: [],
  clientVaccList: [],
  clientVaccTotal: 0,
  onlineOfflineStatus: {},
  client_dashboard_settings: {},
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLIENT_LIST:
      return {
        ...state,
        clientList: action.payload,
      };
    case CLIENT_BILLING_DETAILS:
      return {
        ...state,
        clientBillingDetails: action.payload,
      };
    case BUCKET_CAPTCHA:
      return {
        ...state,
        bucketCaptcha: action.payload,
      };
    //Get VENDOR_LIST
    case VENDOR_LIST:
      return {
        ...state,
        vendorList: action.payload,
      };
    case SEARCH_PATIENT_LIST:
      return {
        ...state,
        search_patient_list: action.payload,
      };
    case SUGGEST_PAT_MESSAGE:
      return {
        ...state,
        suggestionpatmessage: action.payload,
      };
    case SUGGEST_PAT:
      return {
        ...state,
        suggestpat: action.payload,
      };
    case SEARCH_PATIENT_LIST_COUNT:
      return {
        ...state,
        search_patient_list_count: action.payload,
      };

    //Get ERXREPORT data
    case CLIENT_ERX_LIST:
      return {
        ...state,
        clientErxList: action.payload,
      };
    case CLIENT_ERX_TOTAL_FEES:
      return {
        ...state,
        clientTotalErxFees: action.payload,
      };
    //Get completed count data
    case CLIENT_ERX_LIST_COUNT:
      return {
        ...state,
        clientErxListCount: action.payload,
      };
    case CLIENT_DASHBOARD_DETAILS:
      return {
        ...state,
        clientDashboardDetails: action.payload,
      };
    case CLIENT_DASHBOARD_TAT:
      return {
        ...state,
        clientTAT: action.payload,
      };
    case OPERATOR_LIST:
      return {
        ...state,
        operatorList: action.payload,
      };
    case GET_VENDOR_LIST:
      return {
        ...state,
        clientBillingDetails: action.payload,
      };
    case APPOINTMENT_PATIENTS:
      return {
        ...state,
        appointmentPatients: action.payload,
      };
    //Get REJECT_REASONS
    case REJECT_REASONS:
      return {
        ...state,
        rejectReasons: action.payload,
      };
    //Get MEDIAlIST
    case MEDIA_lIST:
      return {
        ...state,
        mediaList: action.payload,
      };
    case CLIENT_INSERT_MSG:
      return {
        ...state,
        clientInsertMsg: action.payload,
      };
    case CLIENT_PATIENT_VACCLIST:
      return {
        ...state,
        clientVaccList: action.payload,
      };
    case CLIENT_PATIENT_VACCTOTAL:
      return {
        ...state,
        clientVaccTotal: action.payload,
      };
    case CLIENT_DOWNLOAD_LIST:
      return {
        ...state,
        clientDownloadList: action.payload,
      };
    case CLIENT_DOWNLOAD_LIST_COUNT:
      return {
        ...state,
        clientDownloadListCount: action.payload,
      };
    //Get blood urea list data
    case bloodureaList:
      return {
        ...state,
        bloodureaList: action.payload,
      };
    //Get serum List
    case serumList:
      return {
        ...state,
        serumList: action.payload,
      };
    //Get tl count
    case tlcount:
      return {
        ...state,
        tlcount: action.payload,
      };
    //Get user permissions
    case USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.payload,
      };
    case SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    //Get hemoglobin List
    case hemoglobinList:
      return {
        ...state,
        hemoglobinList: action.payload,
      };
    //Get spo2 list
    case spo2List:
      return {
        ...state,
        spo2List: action.payload,
      };
    //Get pulse list
    case pulseList:
      return {
        ...state,
        pulseList: action.payload,
      };
    case tempList:
      return {
        ...state,
        tempList: action.payload,
      };
    //Get BP list data
    case bpList:
      return {
        ...state,
        bpList: action.payload,
      };
    //Get dosage list
    case DOSAGELIST:
      return {
        ...state,
        dosageList: action.payload,
      };
    //Get referralList
    case REFERRALLIST:
      return {
        ...state,
        referralList: action.payload,
      };
    //Get pulse list
    case weightList:
      return {
        ...state,
        weightList: action.payload,
      };

    //Get durationList
    case durationList:
      return {
        ...state,
        durationList: action.payload,
      };
    case APPOINTMENT_REASON:
      return {
        ...state,
        appointmentReason: action.payload,
      };
    case CLIENT_EXCEL_LIST:
      return {
        ...state,
        clientExcelDetails: action.payload,
      };
    case VACC_EXCEL_LIST:
      return {
        ...state,
        vaccExcelDetails: action.payload,
      };
    case AGE_VACCINATION_LIST:
      return {
        ...state,
        age_vaccine_list: action.payload,
      };
    case GET_HC_DATA:
      return {
        ...state,
        healthCare: action.payload,
      };

    //Assign dashboard pending data
    case DASHBOARD_PENDING_DATA:
      return {
        ...state,
        dashboardPendingList: action.payload,
      };
    //Assign dashboard pending count
    case DASHBOARD_PENDING_COUNT:
      return {
        ...state,
        pendingCount: action.payload,
      };
    //Assign dashboard pending data
    case DASHBOARD_REJECT_DATA:
      return {
        ...state,
        dashboardRejectList: action.payload,
      };
    //Assign dashboard pending count
    case DASHBOARD_REJECT_COUNT:
      return {
        ...state,
        dashboardRejectcountList: action.payload,
      };

    //Assign dashboard pending Patient success msg
    case PATIENT_SUCCESS_MSG:
      return {
        ...state,
        patientsuccessmsg: action.payload,
      };
    //Assign successmsg
    case ERX_SUCCESS_MSG:
      return {
        ...state,
        erxsuccessmsg: action.payload,
      };
    //Assign successmsg
    case REJECT_ERX_MSG:
      return {
        ...state,
        rejecterxsuccessmsg: action.payload,
      };

    //Assign successmsg
    case MEDICINE_SUCCESS_MSG:
      return {
        ...state,
        erxmedicinesuccessmsg: action.payload,
      };

    case DASHBOARD_ERX_DOCPROCEDURE:
      return {
        ...state,
        dashboarderxDocProcedure: action.payload,
      };

    //Insert prescription
    case DASHBOARD_INSERT_PRESCRIPTION:
      return {
        ...state,
        dashboardinsertprescriptions: action.payload,
      };
    //Assign dashboard inprogress data
    case DASHBOARD_INPROGRESSS_DATA:
      return {
        ...state,
        dashboardInprogressList: action.payload,
      };
    //Assign inprogress count
    case DASHBOARD_INPROGRESSS_COUNT:
      return {
        ...state,
        inprogresCount: action.payload,
      };
    //doctor success msg
    case DOCTOR_SUCCESS_MSG:
      return {
        ...state,
        doctorsuccessmsg: action.payload,
      };

    //doctor update msg
    case DOCTOR_UPDATE_MSG:
      return {
        ...state,
        doctorupdatemsg: action.payload,
      };

    //REJECT_ERX_SUCCESS_MSG
    case REJECT_ERX_SUCCESS_MSG:
      return {
        ...state,
        rejectsuccessmsg: action.payload,
      };

    case DASHBOARD_COMPLETED_DATA:
      return {
        ...state,
        dashboardCompletedList: action.payload,
      };
    case DASHBOARD_COMPLETED_COUNT:
      return {
        ...state,
        dashboardCompletedcountList: action.payload,
      };

    //Assign delete medicine details
    case DELETE_MEDCINE_SUCCESS_MSG:
      return {
        ...state,
        deletemedicinesuccessmsg: action.payload,
      };

    // master list
    case DEPARTMENT_MASTER:
      return {
        ...state,
        departmentMaster: action.payload,
      };
    case CHANGE_MOBILENO_RES:
      return {
        ...state,
        changemobilenores: action.payload,
      };

    case SEARCH_REPORTS_DATA:
      return {
        ...state,
        searchReportsData: action.payload,
      };
    case PATIENT_LISTS:
      return {
        ...state,
        patientListdata: action.payload,
      };
    case SEARCH_REPORTS_DATA:
      return {
        ...state,
        searchReportsData: action.payload,
      };
    case GET_CAMERA_LIST:
      return {
        ...state,
        cameraDash: action.payload,
      };
    case GET_CLIENT_MASTERS:
      return {
        ...state,
        getClientlist: action.payload,
      };
    case DOCTOR_INSERT_MSG:
      return {
        ...state,
        doctorInsertMsg: action.payload,
      };
    case INSERT_OWN_ERX_SUCCESS:
      return {
        ...state,
        owneRx_succes: action.payload,
      };
    case CLIENT_DOCTOR_LIST:
      return {
        ...state,
        doctorList: action.payload,
      };
    case UPLOAD_WARNING:
      return {
        ...state,
        uploadWarning: action.payload,
      };
    case INSERT_ERROR_INDEX:
      return {
        ...state,
        inserErrorIndex: action.payload,
      };
    case OPDDIGITIZATION_COUNT:
      return {
        ...state,
        opddigitization_report_count: action.payload,
      };
    case OPDDIGITIZATION_MEDICINE:
      return {
        ...state,
        opddigitization_report_medicine: action.payload,
      };
    case DIGITIZATION_DISEASE:
      return {
        ...state,
        digitization_report_diseases: action.payload,
      };
    case INVESTIGATION_LAB:
      return {
        ...state,
        inves_labtype: action.payload,
      };
    case INVESTIGATION_RADIO:
      return {
        ...state,
        inves_radiotype: action.payload,
      };
    //Get online offline status
    case ONLINE_OFFLINE_STATUS:
      return {
        ...state,
        onlineOfflineStatus: action.payload,
      };
    case CLIENT_DASHBOARD_SETTINGS:
      return {
        ...state,
        client_dashboard_settings: action.payload,
      };

    default:
      return state;
  }
};
export default dashboardReducer;
