import React, {useContext,useEffect,useState} from 'react';
import AppHeader from './AppHeader';
import {ContentView} from '../../../index';
import Box from '@material-ui/core/Box';
import {useDispatch, useSelector} from 'react-redux';
import useStyles from './index.style';
import clsx from 'clsx';
import AppFooter from './AppFooter';
import AppFixedFooter from './AppFixedFooter';
import AppContext from '../../../utility/AppContext';
import AppSidebar from './AppSidebar';
import {LayoutType} from '../../../../shared/constants/AppEnums';
import Notification from '../../../../modules/common/Notification/Notification';

const HorDefault = (props) => {
  const {footer, layoutType, footerType} = useContext(AppContext);
  const classes = useStyles(props);
  const highPriorityAlert = useSelector(({itAdmin}) => itAdmin.itAlertCount);  
    const [showNotifi, setshowNotifi] = useState(false);
    const [alertNotification, setAlertNotification] = useState(''); 



     function checkAlert(){ 
      let count=localStorage.getItem('clientAlertCount') 
      let message=' request pending for approval';
      if(count > 1)
      {
        message=' requests pending for approval';
      }
      if(count > 0){
        setshowNotifi(true) 
        let msg='You have '+count +''+message+''
        setAlertNotification(msg)
      }
    }

   
    useEffect(()=>{
      setInterval(checkAlert, 120000);
    },[])

  return (
    <Box
      className={clsx(
        classes.appMain,
        'appMainHor',
        layoutType === LayoutType.BOXED ? classes.boxedLayout : '',
        {
          appMainFooter: footer && footerType === 'fluid',
          appMainFixedFooter: footer && footerType === 'fixed',
        },
      )}>
      <AppHeader />
      <Box className={classes.mainContent}>
        <AppSidebar />
        <Box className={classes.mainContainer}>
          <ContentView />
          
        </Box> 
      </Box>
      {showNotifi &&  (
        <Notification message={alertNotification} 
        setshowNotifi={setshowNotifi}
        key='notification' />
      )}
      <AppFooter />
      <AppFixedFooter />
    </Box>
    
  );
};

export default HorDefault;
