import {
    IT_PENDLIST, 
    IT_PENDCOUNT,
    IT_COMPLETELIST,
    IT_COMPLETECOUNT,
    CLIENT_DRLIST,
    CLIENT_CREDLIST,
    MRD_API_RES,
    ADMIN_COUNT,
    ADMIN_EXCEL_LIST,
    ADMIN_LIST,IT_ALERTCOUNT
} from '../../shared/constants/ActionTypes';

const initialState = {
    pendingList: [],
    completedList: [],
    pendingCount: 0,
    completedCount: 0,
    apiResponse:'',
    clientDrList:[],
    clientCredList:[],
    adminList:[],
    adminExcelList:[],
    adminCount:0,
    itAlertCount:0,
  };

const ITAdminReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADMIN_LIST:
        return {
          ...state,
          adminList: action.payload,
        };
        case IT_ALERTCOUNT :
        return{
          ...state,
          itAlertCount:action.payload,
        };
        case ADMIN_EXCEL_LIST:
          return {
            ...state,
            adminExcelList: action.payload,
          };
        case ADMIN_COUNT:
          return {
            ...state,
            adminCount: action.payload,
          };
      case CLIENT_DRLIST:
        return {
          ...state,
          clientDrList: action.payload,
        };
        case CLIENT_CREDLIST:
          return {
            ...state,
            clientCredList: action.payload,
          };
        case IT_PENDLIST:
          return {
            ...state,
            pendingList: action.payload,
          };
          case MRD_API_RES:
            return {
              ...state,
              apiResponse: action.payload,
            };
          case IT_PENDCOUNT:
            return {
              ...state,
              pendingCount: action.payload,
            };
            case IT_COMPLETELIST:
                return {
                  ...state,
                  completedList: action.payload,
                };
                case IT_COMPLETECOUNT:
                    return {
                      ...state,
                      completedCount: action.payload,
                    };
          default:
            return state;
        }
        
}

export default ITAdminReducer;